import { z } from "zod";

import { Storage } from "../storage";
import { OrangeError } from "./orangeError";
import { OrangeProviderId } from "./orangeProviderId";
import { UserData } from "./userData";

const REFRESH_TOKEN_KEY = "orange_refresh_token";
const REFRESH_TOKEN_EXPIRES_AT_KEY = "orange_refresh_token_expires_at";

const _config = {
  prod: {
    api: "https://proxy.orange.france.tv",
    userData: "/partners/FTV/orangetv4partners/user-data/v1/partners/FTV/channels/catchuptv_fr2_rng/profile",
    replayPosition: "/partners/FTV/orangetv4partners/replay-positions/v1/partners/FTV/providers",
    errorLogsCollector: "/partners/FTV/orangetv4partners/error-logs-collector/v2/partners/FTV/records",
  },
  pre: {
    api: "https://proxy.orange.francetv-preprod.fr",
    userData: "/partners/FTV/orangetv4partners/preprod/user-data/v1/partners/FTV/channels/catchuptv_fr2_rng/profile",
    replayPosition: "/partners/FTV/orangetv4partners/preprod/replay-positions/v1/partners/FTV/providers",
    errorLogsCollector: "/partners/FTV/orangetv4partners/preprod/error-logs-collector/v2/partners/FTV/records",
  },
  dev: {
    api: "https://proxy-mediation.yatta.francetv.fr/preprod/v3/proxy-orange",
    userData: "/partners/FTV/orangetv4partners/preprod/user-data/v1/partners/FTV/channels/catchuptv_fr2_rng/profile",
    replayPosition: "/partners/FTV/orangetv4partners/preprod/replay-positions/v1/partners/FTV/providers",
    errorLogsCollector: "/partners/FTV/orangetv4partners/preprod/error-logs-collector/v2/partners/FTV/records",
  },
} as const;

const _backendTarget: "prod" | "pre" | "dev" = (() => {
  if (__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy") {
    return "prod";
  }
  if (__BACKEND_TARGET__ === "pre") {
    return "pre";
  }
  if (__BACKEND_TARGET__ === "dev") {
    return "dev";
  }

  // default
  return "pre";
})();

const EXPIRES_IN_ATTRIBUTE = "expires_in" as const;
const REFRESH_TOKEN_EXPIRES_IN_ATTRIBUTE = "refresh_token_expires_in" as const;

const OrangeToken = z.object({
  access_token: z.string(),
  [EXPIRES_IN_ATTRIBUTE]: z.number(),
  refresh_token: z.string(),
  [REFRESH_TOKEN_EXPIRES_IN_ATTRIBUTE]: z.number(),
});
type OrangeToken = z.infer<typeof OrangeToken>;

// DEBUG for loacalhost
// Storage.setItem(
//   REFRESH_TOKEN_KEY,
//   "iYYHkGobmB8kFPs2yCUyKai7QI5g6uWmxwHT9bgR7XWh+brksiEW7kHa5enoFCzr16N2FWqp6SOTHmfKZUl/m9wU6rt7mEmLKleZQcwk7PLORiKPl8ghjEs2d8aI+Mtzxzrz7RR21O2q/6nFwSInTo2MRjooqXSshUzn7muC4ks7emqKrzxL2UJFMgFltBt2/GfqhuwXj/632UeDtdOszFGWStKsZA8f7/gs/kqD+CgVSYjs7D1luPAc/wWLrFRkR1bx+M/f7rs19pwIP2qhcgyL262CFJbJNRh5nkjeJzjNFQa5NY49JzxrU88BTtdxWzrPjn8VNy7FwtU69LdMPw=="
// );
// Storage.setItem(REFRESH_TOKEN_EXPIRES_AT_KEY, "1736420351398");

export class ApiOrange {
  private static readonly _url = _config[_backendTarget].api;
  private static readonly _userDataUrl = _config[_backendTarget].api + _config[_backendTarget].userData;
  private static readonly _replayPositionUrl = _config[_backendTarget].api + _config[_backendTarget].replayPosition;
  private static readonly _errorLogsCollectorUrl =
    _config[_backendTarget].api + _config[_backendTarget].errorLogsCollector;
  private static _accessToken?: string;
  private static _accessTokenExpiresAt?: number;

  private static _genericFetchAccessToken = async <T>(callback: (accessToken: string) => Promise<T>): Promise<T> => {
    if (
      ApiOrange._accessToken !== undefined &&
      ApiOrange._accessTokenExpiresAt !== undefined &&
      ApiOrange._accessTokenExpiresAt > Date.now()
    ) {
      // we have a valid accessToken
      return callback(ApiOrange._accessToken);
    } else {
      const refreshToken = Storage.getItem(REFRESH_TOKEN_KEY);
      const refreshTokenExpiresAt = Storage.getItem(REFRESH_TOKEN_EXPIRES_AT_KEY);
      if (refreshToken !== null && refreshTokenExpiresAt !== null && Number(refreshTokenExpiresAt) > Date.now()) {
        // we have a valid refreshToken
        ApiOrange._accessToken = undefined;
        ApiOrange._accessTokenExpiresAt = undefined;
        const token = await ApiOrange._reconnect(refreshToken);
        ApiOrange._saveToken(token);
        return callback(token.access_token);
      } else {
        // we need a brand new accessToken
        ApiOrange._clearToken();
        const token = await ApiOrange._auth();
        ApiOrange._saveToken(token);
        return callback(token.access_token);
      }
    }
  };

  private static _clearToken = () => {
    ApiOrange._accessToken = undefined;
    ApiOrange._accessTokenExpiresAt = undefined;
    Storage.removeItem(REFRESH_TOKEN_KEY);
    Storage.removeItem(REFRESH_TOKEN_EXPIRES_AT_KEY);
  };

  private static _saveToken = (token: OrangeToken) => {
    ApiOrange._accessToken = token.access_token;
    ApiOrange._accessTokenExpiresAt = Date.now() + token.expires_in * 1000;
    Storage.setItem(REFRESH_TOKEN_KEY, token.refresh_token);
    Storage.setItem(REFRESH_TOKEN_EXPIRES_AT_KEY, (Date.now() + token.refresh_token_expires_in * 1000).toString());
  };

  private static _auth = (): Promise<OrangeToken> => {
    return new Promise<OrangeToken>((resolve, reject) => {
      const iFrameElement = document.createElement("iframe");
      iFrameElement.name = "token";
      iFrameElement.style.width = "0px";
      iFrameElement.style.height = "0px";
      iFrameElement.src = ApiOrange._url + "/auth";

      iFrameElement.onload = async _ => {
        const href = iFrameElement.contentDocument?.location.href;
        iFrameElement.onload = null;
        iFrameElement.parentElement?.removeChild(iFrameElement);

        if (typeof href === "string") {
          const urlObject = new URL(href);
          const objectFromUrlParams: Record<string, string | number> = {};
          urlObject.searchParams.forEach((value, key) => {
            if (key === EXPIRES_IN_ATTRIBUTE || key === REFRESH_TOKEN_EXPIRES_IN_ATTRIBUTE) {
              objectFromUrlParams[key] = Number(value);
            } else {
              objectFromUrlParams[key] = value;
            }
          });
          try {
            resolve(OrangeToken.parse(objectFromUrlParams));
          } catch (e) {
            Log.api.error("ApiOrange auth failed, parsing urlParams error", objectFromUrlParams, e);
            reject(e);
          }
        } else {
          const errMsg = "ApiOrange auth failed, href is undefined";
          Log.api.error(errMsg);
          reject(errMsg);
        }
      };
      document.body.appendChild(iFrameElement);
    });
  };

  private static _reconnect = async (refreshToken: string): Promise<OrangeToken> => {
    const response = await fetch(ApiOrange._url + "/reconnect", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-refresh-token": refreshToken,
      },
    });
    return OrangeToken.parse(await wrapFetchResponse("reconnect failed", response));
  };

  static fetchUserData = (): Promise<UserData> => {
    return ApiOrange._genericFetchAccessToken(async (accessToken: string) => {
      const response = await fetch(ApiOrange._userDataUrl, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      });
      return UserData.parse(await wrapFetchResponse("fetchUserData failed", response));
    });
  };

  static fetchReplayPosition = (
    contentId: string,
    timecode: number,
    providerId: OrangeProviderId
  ): Promise<unknown> => {
    return ApiOrange._genericFetchAccessToken(async (accessToken: string) => {
      const response = await fetch(ApiOrange._replayPositionUrl + "/" + providerId + "/articles", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ contentId, timecode: timecode * 1000 }), // timecode is in seconds but orange store it in milliseconds
      });
      return await wrapFetchResponse("fetchReplayPosition", response);
    });
  };

  static fetchErrorLogsCollector = (orangeError: OrangeError): Promise<unknown> => {
    return ApiOrange._genericFetchAccessToken(async (accessToken: string) => {
      const response = await fetch(ApiOrange._errorLogsCollectorUrl, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orangeError),
      });
      return await wrapFetchResponse("fetchErrorLogsCollector", response);
    });
  };
}

const wrapFetchResponse = async (logErrMsg: string, response: Response): Promise<unknown> => {
  const data = response.status !== 204 ? await response.json() : undefined;
  if (response.ok === false) {
    Log.api.error("ApiOrange", logErrMsg, response, data);
    throw { data: data, response: response };
  }
  return data;
};
